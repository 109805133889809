<template>
  <div class="pa-5">
    <v-dialog v-model="Calc.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <CalcWithEmit v-if="Calc.Open" @calcclose="[(Calc.Open = false)]" @calcvalue="GetCalcResult" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="Print.Open" width="40vw">
      <v-container class="background pa-7" fluid>
        <PrintsOrder v-if="Print.Open" :order="Print.Item" :user="user" :type="Print.Type" />
      </v-container>
    </v-dialog>
    <!--Refun Dialog -->
    <v-dialog v-model="Refund.Open" width="60vw">
      <v-container class="background pa-7" fluid>
      <v-col class="mt-0 pt-0" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">
          <v-icon large class="pt-0 mt-0" v-on:click="Refund.Open = false">mdi-close-circle-outline</v-icon>
        </v-col>
        <h2>{{$Dictionary.GetString('refund')}}</h2>
      </v-container>
    </v-dialog>

    <v-dialog v-model="PrintsBonAbb.Open" width="40vw">
      <v-container class="background pa-7" fluid>
        <PrintsBonAbb v-if="PrintsBonAbb.Open" :order="Print.Item" :user="user" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="Edit.Open" width="50vw">
      <v-container class="background pa-7 rounded" fluid>
        <OrderRefund v-if="Edit.Open" :order="Edit.Item" :user="user" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="Money.Open" width="30vw">
      <v-container class="background pa-7" fluid>
        <v-col>
          <v-card-subtitle>EC_Kart : {{ Number(Money.TotalMoney.EC_Kart).toFixed(2) }} € </v-card-subtitle>
          <v-card-subtitle>Bar : {{ Number(Money.TotalMoney.Bar).toFixed(2) }} € </v-card-subtitle>
        </v-col>
      </v-container>
    </v-dialog>
    <Title :title="$Dictionary.GetString('addorders')" />
    <v-row class="mt-3">
      <v-col cols="12" md="3">
        <v-menu v-model="Orders.DataToSend.menuFrom" :close-on-content-click="false" :nudge-right="60"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$Dictionary.GetString('fromdate')" autocomplete="new-date-from"
              v-model="Orders.DataToSend.from" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"
              prepend-inner-icon="mdi-calendar" filled rounded hide-details="" dense single-line v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="Orders.DataToSend.from" @input="Orders.DataToSend.menuFrom = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <v-menu ref="menu1" v-model="Orders.DataToSend.menuTimeFrom" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="Orders.DataToSend.fromtime" transition="scale-transition" min-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="Orders.DataToSend.fromtime" :label="$Dictionary.GetString('fromtime')"
              prepend-inner-icon="mdi-clock-time-four-outline" v-bind="attrs" readonly v-on="on"
              autocomplete="new-time-from" filled rounded hide-details="" dense single-line
              :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"></v-text-field>
          </template>
          <v-time-picker no-title v-if="Orders.DataToSend.menuTimeFrom" v-model="Orders.DataToSend.fromtime"
            @click:minute="$refs.menu1.save(Orders.DataToSend.fromtime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <v-menu v-model="Orders.DataToSend.menuTo" :close-on-content-click="false" :nudge-right="60"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$Dictionary.GetString('todate')" autocomplete="new-date-to"
              v-model="Orders.DataToSend.to" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"
              prepend-inner-icon="mdi-calendar" filled rounded hide-details="" dense single-line v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="Orders.DataToSend.to" @input="Orders.DataToSend.menuTo = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <v-menu ref="menu" v-model="Orders.DataToSend.menuTimeTo" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="Orders.DataToSend.totime" transition="scale-transition" min-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="Orders.DataToSend.totime" :label="$Dictionary.GetString('totime')"
              prepend-inner-icon="mdi-clock-time-four-outline" v-bind="attrs" readonly v-on="on"
              autocomplete="new-time-from" filled rounded hide-details="" dense single-line
              :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"></v-text-field>
          </template>
          <v-time-picker no-title v-if="Orders.DataToSend.menuTimeTo" v-model="Orders.DataToSend.totime"
            @click:minute="$refs.menu.save(Orders.DataToSend.totime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" v-if="user.PublicSetting.Type == 2">
        <v-select v-model="Orders.DataToSend.table_nr" :items="Orders.Tables" filled dense single-line rounded
          hide-details="" item-text="name" item-value="value" prepend-inner-icon="mdi-food"
          :label="$Dictionary.GetString('tablenr')" @change="GetOrders(false)"></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select v-model="Orders.DataToSend.creater" :items="Orders.Creaters" filled dense single-line rounded
          hide-details="" item-text="name" item-value="value" prepend-inner-icon="mdi-account-search"
          :label="$Dictionary.GetString('users')" @change="GetOrders(false)"></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select v-model="Orders.DataToSend.type" :items="Orders.Types" filled dense single-line rounded hide-details=""
          item-text="name" item-value="value" prepend-inner-icon="mdi-account-search"
          :label="$Dictionary.GetString('ordertypes')" @change="GetOrders(false)"></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model="Orders.Search" clearable prepend-inner-icon="mdi-barcode-scan"
          @click:append="[(Calc.Open = true)]" append-icon="mdi-calculator"
          :label="$Dictionary.GetString('searchbyreceiptnr')" @change="GetOrders(true)" rounded dense filled single-line>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn depressed rounded class="Block mt-1" @click="[(Money.Open = true)]">
          {{ $Dictionary.GetString("totalsales") }}
          <v-icon class="ml-12" v-bind:class="$vuetify.rtl ? 'mr-12' : 'ml-12'"> mdi-account-convert-outline </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="py-0 my-0">
        <v-btn block rounded color="primary" :disabled="(Orders.DataToSend.from && Orders.DataToSend.to) == false"
          @click="GetOrders(false)">
          <v-icon> mdi-update</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="py-0 my-0">
        <v-btn block rounded @click="ExportToExcel" color="success"> <v-icon>mdi-export-variant</v-icon>&nbsp;Excel
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-5" />
    <v-row justify="center" align="center" class="mb-1 mt-1">
      <v-col cols="12">
        <v-pagination circle v-model="Orders.Settings.CurrentPage" :length="Orders.Settings.Total"
          v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left"
          @input="GetOrders(false)"></v-pagination>
      </v-col>
    </v-row>
    <v-data-table :items="Orders.Items" :loading="Orders.Loading" :loading-text="$Dictionary.GetString('loading')"
      hide-default-footer disable-pagination :headers="Orders.Headers">
      <template v-slot:top>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-chip class="Block headline" color="secondary" style="height: 40px">{{ $Dictionary.GetString("boxtotal") }}
              : {{ Orders.Total.toFixed(2) }} €</v-chip>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.transactionID`]="{ item }">
        <div class="my-3">
          <v-row align="center" align-content="center" no-gutters>
            <v-col cols="9">
              <v-chip class="mx-1 mb-2 Warning success"> <v-icon dark
                  v-bind:class="$vuetify.rtl ? 'ml-2' : 'mr-2'">mdi-account</v-icon>{{ item.created_by }} </v-chip>
              <v-chip v-if="item.type == 1" class="mb-2 mx-1 white--text rounded-pill success"> <v-icon
                  dark>mdi-information-outline</v-icon>&nbsp;&nbsp;{{ $Dictionary.GetString("invoice") + " #" +
                    item.receipt_nr }} </v-chip>

              <v-chip v-else-if="item.type == 2" class="mb-2 mx-1 white--text rounded-pill warning"> <v-icon
                  dark>mdi-information-outline</v-icon>&nbsp;&nbsp;{{ $Dictionary.GetString("refund") + " #" +
                    item.returned_receipt_nr + " | " + $Dictionary.GetString("invoice") + " #" + item.receipt_nr }} </v-chip>
              <v-chip v-else-if="item.type == 3" class="mb-2 mx-1 white--text rounded-pill error"> <v-icon
                  dark>mdi-information-outline</v-icon>&nbsp;&nbsp;{{ $Dictionary.GetString("canceledinvoice") + "#" +
                    item.bon_abbr }} </v-chip>
              <v-chip class="mx-1 mb-1 success">
                <v-icon dark v-bind:class="$vuetify.rtl ? 'ml-2' : 'mr-2'">mdi-clock-outline</v-icon>
                {{ $Moment(item.created_at).format("HH:mm:ss - dddd DD.MM.YYYY") }}
              </v-chip>
              <v-chip class="success">TSE : {{ item.transactionID2 == "" ? item.transactionID : item.transactionID2
              }}</v-chip>
            </v-col>
          </v-row>
          <v-row class="mt-2" no-gutters>
            <v-col cols="7">
              <v-chip class="mx-1" v-bind:class="{
                  secondary: item.payment_method == 1,
                  warning: item.payment_method == 2,
                  info: item.payment_method == 3,
                  error: item.payment_method == 4,
                }">
                <v-icon dark v-bind:class="$vuetify.rtl ? 'ml-2' : 'mr-2'">mdi-card-text</v-icon>{{
                  $Dictionary.GetTheRightPaymentMethod(item.payment_method) }}
              </v-chip>
              <v-chip class="mx-1 info">7% : {{ item.tax_7 }} €</v-chip>
              <v-chip class="mx-1 info">19% : {{ item.tax_19 }} €</v-chip>
              <v-chip class="mx-1 info">Netto : {{ item.netto }} €</v-chip>
              <v-chip class="mx-1 info">Brutto : {{ item.brutto }} €</v-chip>
              <v-chip class="mx-1 info">Pfand : {{ item.pledge }} €</v-chip>
            </v-col>
            <v-checkbox dense hide-details="" v-if="user.PublicSetting.Type == 2" disabled v-model="item.place"
              :label="$Dictionary.GetString('dinein')"></v-checkbox>

          </v-row>

          <v-row class="mt-2" no-gutters>
            <v-col cols="8">
              <v-chip class="mx-1 mb-2 px-3 font-weight-bold" v-for="product in item.order_detals" :key="product.id">
                <span class="secondary white--text rounded-lg py-0 px-3">{{ product.QTY }} {{
                  $Dictionary.GetTheRightWeight(product.unit) }}</span>&nbsp;&nbsp;
                {{ $Dictionary.GetTheRightValue(product.product_name_ar, product.product_name_tr, product.product_name_de)
                }}
              </v-chip>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.brutto`]="{ item }">
        <v-chip class="Block secondary text-end">TSE :
          {{ (Number(item.brutto) + Number(item.pledge)).toFixed(2) }}
          €</v-chip>

        <v-chip class="Block secondary text-end mt-2">LIEKASSE : {{ (Number(item.brutto) + Number(item.pledge)).toFixed(2)
        }} € </v-chip>
      </template>

      <template v-slot:[`item.table_nr`]="{ item }">
        <v-btn v-if="user.PublicSetting.Type == 2"
          @click="[(Orders.DataToSend.table_nr = item.table_nr == '0' ? -1 : item.table_nr)]" fab
          class="warning text-center mx-auto mt-2">{{ item.table_nr }} </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <!-- Order -->
        <v-tooltip v-if="item.type != 3" bottom :color="LSSettings.UserInfo.Dark ? 'red' : ''">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small fab elevation="0" class="success mx-1 my-1" v-bind="attrs" v-on="on"
              @click="[(Print.Open = true), (Print.Item = item.receipt_nr), (Print.Type = 1)]">
              <v-icon> mdi-printer-settings </v-icon>
            </v-btn>
          </template>
          <span> <v-icon dark> mdi-information-outline </v-icon>&nbsp;{{ $Dictionary.GetString("invoice") }} : #{{
            item.receipt_nr }} </span>
        </v-tooltip>

        <!-- Refund Order -->
        <v-tooltip v-if="item.returned_receipt_nr != null" bottom :color="LSSettings.UserInfo.Dark ? 'red' : ''">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small fab elevation="0" class="warning mx-1 my-1" v-bind="attrs" v-on="on"
              @click="[(Print.Open = true), (Print.Item = item.returned_receipt_nr), (Print.Type = 2)]">
              <v-icon> mdi-printer-settings </v-icon>
            </v-btn>
          </template>
          <span> <v-icon dark> mdi-information-outline </v-icon>&nbsp;{{ $Dictionary.GetString("refund") }} : #{{
            item.returned_receipt_nr }} </span>
        </v-tooltip>

        <!--Cancel Order -->

        <v-tooltip v-if="item.type == 3" bottom :color="LSSettings.UserInfo.Dark ? 'red' : ''">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small fab elevation="0" class="error mx-1 my-1" v-bind="attrs" v-on="on"
              @click="[(PrintsBonAbb.Open = true), (Print.Item = item.id)]">
              <v-icon> mdi-printer-settings </v-icon>
            </v-btn>
          </template>
          <span> <v-icon dark> mdi-information-outline </v-icon>&nbsp;{{ $Dictionary.GetString("canceledinvoice") }} : #{{
            item.receipt_nr }} </span>
        </v-tooltip>
        <v-btn   x-small fab elevation="0"
          color="secondary" class="mx-1 my-1" @click="[(Refund.Open = true)]">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>

        <!--   <v-btn v-if="item.type == 1" :disabled="item.returned_receipt_nr != null" x-small fab elevation="0"
          color="secondary" class="mx-1 my-1" @click="[(Edit.Open = true), (Edit.Item = item)]">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      -->
      </template>
    </v-data-table>
    <v-row justify="center" align="center" class="mb-1 mt-1">
      <v-col cols="12">
        <v-pagination circle v-model="Orders.Settings.CurrentPage" :length="Orders.Settings.Total"
          v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left"
          @input="GetOrders(false)"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Title from "../Shared/Title.vue";
import PrintsOrder from "../Shared/Prints/PrintsOrder.vue";
import OrderRefund from "../Shared/OrderRefund.vue";
import CalcWithEmit from "../Shared/CalcWithEmit.vue";
import PrintsBonAbb from "../Shared/Prints/PrintsBonAbb.vue";

export default {
  components: { Title, PrintsOrder, OrderRefund, CalcWithEmit, PrintsBonAbb },
  data() {
    return {
      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      LSSettings: JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())),
      Calc: {
        Open: false,
        Result: 0,
      },
      Refund:{
        Open:false
      },
      Orders: {
        Loading: true,
        Settings: {
          CurrentPage: 1,
          Total: 0,
        },
        Tables: [],
        Creaters: [],
        Total: 0,
        Search: "",
        Items: [],
        Types: [],
        Headers: [
          {
            text: "#",
            align: "start",
            value: "receipt_nr",
            width: "40px",
          },
          {
            text: this.$Dictionary.GetString("installgeneralinfos"),
            align: "start",
            value: "transactionID",
            sortable: false,
            filterable: false,
          },
          {
            text: this.$Dictionary.GetString("boxtotal"),
            align: "start",
            value: "brutto",
            sortable: true,
            filterable: false,
          },

          {
            text: this.user.PublicSetting.Type == 2 ? this.$Dictionary.GetString("tablenr") : "",
            align: "center",
            value: this.user.PublicSetting.Type == 2 ? "table_nr" : "",
            sortable: true,
            filterable: false,
          },
          {
            text: "Actions",
            align: "end",
            width: "200px",
            value: "actions",
            sortable: false,
            filterable: false,
          },
        ],
        DataToSend: {
          table_nr: -1,
          creater: -1,
          type: 1,
          from: "",
          fromtime: "00:00",
          to: "",
          totime: "23:59",
          menuFrom: false,
          menuTo: false,
          menuTimeFrom: false,
          menuTimeTo: false,
        },
      },
      Print: {
        Item: {},
        Type: "",
        Open: false,
      },
      PrintsBonAbb: {
        Open: false,
      },
      Edit: {
        Item: {},
        Type: "",
        Open: false,
      },
      Money: {
        Open: false,
        TotalMoney: {},
      },
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    ExportToExcel() {
      if (this.Orders.Items.length > 0) {
        let { json2excel, excel2json } = require("js2excel");
        let data = this.Orders.Items;
        let Name = "Orders Data " + String(this.$Moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss"));
        try {
          json2excel({
            data,
            name: String(Name),
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.error("Export Error");
        }
      }
    },
    GetCalcResult(Value) {
      this.Orders.Search = Value;
      this.GetOrders(true);
    },
    GetTypes() {
      this.Orders.Types = [
        {
          name: this.$Dictionary.GetString("invoice"),
          value: 1,
        },
        {
          name: this.$Dictionary.GetString("refund"),
          value: 2,
        },
        {
          name: this.$Dictionary.GetString("canceledinvoice"),
          value: 3,
        },
      ];
    },
    GetUsers() {
      this.$http
        .get(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API21()), this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((ResultUsers) => {
          this.Orders.Creaters.push({
            name: this.$Dictionary.GetString("users"),
            value: -1,
          });
          ResultUsers.data.forEach((Element) => {
            this.Orders.Creaters.push({
              name: Element.name,
              value: Element.id,
            });
          });
        })
        .catch((Error) => {
          console.log(Error);
        });
    },
    GetTotalMoney() {
      this.Orders.Loading = true;
      var AxiosConfigSum = {
        url: this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API29()),
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.InstallValues.LiekasseAT,
          creater: this.Orders.DataToSend.creater,
          from: this.Orders.DataToSend.from + " " + this.Orders.DataToSend.fromtime,
          to: this.Orders.DataToSend.to + " " + this.Orders.DataToSend.totime,
        },
      };
      this.$http(AxiosConfigSum)
        .then((OrdersTotalMoney) => {
          this.Money.TotalMoney.Bar = OrdersTotalMoney.data.Bar;
          this.Money.TotalMoney.EC_Kart = OrdersTotalMoney.data.EC_Kart;
          this.Orders.Loading = false;
        })
        .catch((Error) => console.log("TotalMoney : ", Error));
    },
    GetOrders(Search) {
      this.Orders.Items = [];
      this.Orders.Total = 0;
      this.Orders.Loading = true;
      var AxiosConfig = {
        url: Search == false ? this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API7()) + "?page=" + this.Orders.Settings.CurrentPage : this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API7ByNr1()) + this.Orders.Search,
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.InstallValues.LiekasseAT,
          table: this.Orders.DataToSend.table_nr,
          creater: this.Orders.DataToSend.creater,
          type: this.Orders.DataToSend.type,
          from: this.Orders.DataToSend.from + " " + this.Orders.DataToSend.fromtime,
          to: this.Orders.DataToSend.to + " " + this.Orders.DataToSend.totime,
        },
      };
      var AxiosConfigSum = {
        url: this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API7Sum()),
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.InstallValues.LiekasseAT,
          table: this.Orders.DataToSend.table_nr,
          creater: this.Orders.DataToSend.creater,
          type: this.Orders.DataToSend.type,
          from: this.Orders.DataToSend.from + " " + this.Orders.DataToSend.fromtime,
          to: this.Orders.DataToSend.to + " " + this.Orders.DataToSend.totime,
        },
      };
      this.$http(AxiosConfig)
        .then((OrdersData) => {
          this.Orders.Items = OrdersData.data.data;
          this.Orders.Settings.Total = OrdersData.data.meta.last_page;

          this.Orders.Tables.push({
            name: this.$Dictionary.GetString("tablescount"),
            value: -1,
          });
          for (let Count = 0; Count <= this.user.PrivateSetting.Restaurant.Tables; Count++) {
            this.Orders.Tables.push({ name: Count, value: Count });
          }
          this.$http(AxiosConfigSum)
            .then((OrdersSumData) => {
              this.Orders.Total = OrdersSumData.data.total;
              this.Orders.Loading = false;
              this.GetTotalMoney();
            })
            .catch((Error) => console.log("Error 1 : ", Error));
        })
        .catch((Error) => console.log("Error 0 : ", Error));
    },
  },
  mounted() {
    var Today = new Date();
    this.Orders.DataToSend.from = this.$Moment(String(Today)).format("YYYY-MM-DD");
    this.Orders.DataToSend.to = this.$Moment(String(Today)).format("YYYY-MM-DD");
    this.GetOrders(false);
    this.GetUsers();
    this.GetTypes();
  },
};
</script>
