<template>
  <div class="pa-5">
    <Title :title="Dictionary.GetString('addproducts')" />
    <v-dialog v-model="CategoriesDialog" width="60vw">
      <v-container class="background px-3 py-6" fluid>
        <Categories v-if="CategoriesDialog" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="Print.Open" width="40vw">
      <v-container class="background pa-7" fluid>
        <PrintsProduct v-if="Print.Open" :user="user" :product="Print.Item" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="Calc.Open" width="50vw">
      <v-container class="background pa-7" fluid>
        <Calc v-if="Calc.Open" />
        <v-row align="center" justify="center" class="mb-1">
          <v-btn large class="mx-2 mt-3" color="error" @click="Calc.Open = false" rounded>
            <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-close-circle-outline</v-icon>
            {{ Dictionary.GetString("cancel") }}
          </v-btn>
          <v-btn large class="mx-2 mt-3" color="success" @click="CalcSave(Calc.Switcher)" rounded>
            <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-content-save-all-outline</v-icon>
            {{ Dictionary.GetString("save") }}
          </v-btn>
        </v-row>
      </v-container>
    </v-dialog>
    <v-row justify="center" align="center" class="mb-1 mt-1">
      <v-col cols="12" md="2">
        <v-pagination circle v-model="Products.Settings.CurrentPage" v-bind:class="$vuetify.breakpoint.sm ? '' : 'float-start'" :length="Products.Settings.Total" v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="ProductsSwitcher(Products.Switcher)"></v-pagination>
      </v-col>
      <v-col cols="4" class="mt-1">
        <v-menu offset-y content-class="elevation-2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" rounded depressed>
              {{ $Dictionary.GetString("orderproducts") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn rounded depressed @click="[UpdateSortProductInLocalStorage('date')]">
                <v-card-subtitle class="font-weight-bold pa-0 py-0 ma-0 my-0"> {{ $Dictionary.GetString("newestooldest") }}</v-card-subtitle>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn rounded depressed @click="[UpdateSortProductInLocalStorage('name')]">
                <v-card-subtitle class="font-weight-bold pa-0 py-0 ma-0 my-0"> {{ $Dictionary.GetString("byname") }}</v-card-subtitle>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <v-layout class="pt-3" v-bind:class="$vuetify.breakpoint.sm ? 'align-center' : 'align-end'" column>
          <v-flex>
            <v-switch v-model="Products.List" inset flat class="mt-2" :label="Dictionary.GetString('listpreview')"> </v-switch>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          single-line
          class="mx-4 font-weight-bold text-xl-caption"
          :label="Dictionary.GetString('recordsperpage')"
          v-model="Products.Settings.RowsPerPage"
          filled
          rounded
          hide-details=""
          dense
          prepend-inner-icon="mdi-library-shelves"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          :items="[
            { name: '50', value: 50 },
            { name: '75', value: 75 },
            { name: '100', value: 100 },
            { name: Dictionary.GetString('showall'), value: 1000000000000000 },
          ]"
          item-text="name"
          item-value="value"
          @change="[(Products.Settings.CurrentPage = 1), ProductsSwitcher(Products.Switcher)]"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="1" v-bind:class="$vuetify.breakpoint.sm ? 'text-center' : 'text-end'">
        <v-btn color="info" small v-bind:fab="$vuetify.breakpoint.sm ? false : true" @click="[(Product.Type = 'New'), (Product.Open = true), (Product.Item = {}), (Product.Title = Dictionary.GetString('addproduct'))]">
          <v-icon>mdi-pencil-plus-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-2" justify="center">
      <v-col :md="user.PrivateSetting.NumberOfMenus > 1 ? 3 : 4">
        <v-text-field ref="Barcode" v-model="Products.Barcode" @click:clear="ProductsSwitcher(0)" @click:append="[(Calc.Open = true), (Calc.Switcher = 0)]" append-icon="mdi-calculator" @keyup.enter="ProductsSwitcher(1)" clearable prepend-inner-icon="mdi-barcode-scan" class="font-weight-bold text-xl-caption" :label="Dictionary.GetString('barcode') + ' (' + Dictionary.GetString('search') + ')'" rounded filled single-line dense></v-text-field>
      </v-col>
      <v-col :md="user.PrivateSetting.NumberOfMenus > 1 ? 3 : 4">
        <v-form autocomplete="off" @submit.prevent>
          <v-autocomplete dense clearable :search-input.sync="Products.Search.Sync" :items="Products.Search.Items" :item-value="Dictionary.GetTheRightValue('name_ar', 'name_tr', 'name_de')" class="font-weight-bold text-xl-caption" :item-text="Dictionary.GetTheRightValue('name_ar', 'name_tr', 'name_de')" rounded filled single-line prepend-inner-icon="mdi-search-web" :label="Dictionary.GetString('title')" :no-data-text="Dictionary.GetString('noresult')" :loading="Products.Search.Loading" v-model="Products.Search.Value"></v-autocomplete>
        </v-form>
      </v-col>
      <v-col>
        <v-row align="center" dense>
          <v-btn v-show="user.PrivateSetting.NumberOfMenus > 1" v-for="item in user.PrivateSetting.NumberOfMenus" :key="item.id" class="ml-4 text-caption text-lowercase" @click="Products.Settings.group = item" width="40" height="30"> Group {{ item }} </v-btn>
          <v-col>
            <v-select dense single-line hide-details="" :label="Dictionary.GetString('addcategory')" class="shrink font-weight-bold text-xl-caption" v-model="Products.Category" filled rounded prepend-inner-icon="mdi-shape" :menu-props="{ offsetY: true, closeOnClick: true }" :items="Categories" @change="[(Products.Settings.CurrentPage = 1), ProductsSwitcher(3)]" :item-text="Dictionary.GetTheRightValue('name_ar', 'name_tr', 'name_de')" item-value="id"></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="Product.Open" width="85vw">
      <v-container class="background pa-1" fluid>
        <v-form ref="InputForm" lazy-validation>
          <v-card flat>
            <v-card-title>
              <v-row align="center" justify="start" class="my-0 mx-1 caption">
                <h1 class="font-weight-regular font-weight-bold"><v-icon large v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-pencil-plus-outline</v-icon>{{ Product.Title }}</h1>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field filled rounded prepend-inner-icon="mdi-star" :label="Dictionary.GetString('ARvalue')" dense single-line v-model="Product.Item.name_ar" hide-details="" class="font-weight-bold text-xl-caption"> </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field filled rounded prepend-inner-icon="mdi-star" :label="Dictionary.GetString('DEvalue')" dense single-line v-model="Product.Item.name_de" :rules="[(v) => !!v || Dictionary.GetString('noempty')]" hide-details="" class="font-weight-bold text-xl-caption"> </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field filled rounded prepend-inner-icon="mdi-star" :label="Dictionary.GetString('TRvalue')" dense single-line v-model="Product.Item.name_tr" hide-details="" class="font-weight-bold text-xl-caption"> </v-text-field>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-text-field filled rounded prepend-inner-icon="mdi-warehouse" hide-details="" dense single-line :label="Dictionary.GetString('company')" v-model="Product.Item.company" class="font-weight-bold text-xl-caption"></v-text-field>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-text-field hide-details="" filled rounded prepend-inner-icon="mdi-printer" dense single-line :label="Dictionary.GetString('printer')" v-model="Product.Item.printer_nr" class="font-weight-bold text-xl-caption"></v-text-field>
                </v-col>
                <v-col cols="4" class="pt-0 mb-1">
                  <v-select
                    :label="Dictionary.GetString('weightorvolumn')"
                    v-model="Product.Item.unit"
                    filled
                    dense
                    single-line
                    hide-details=""
                    rounded
                    prepend-inner-icon="mdi-shape"
                    class="font-weight-bold text-xl-caption"
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    :items="[
                      { id: 1, text: Dictionary.GetString('piece') },
                      { id: 2, text: Dictionary.GetString('kilogram') },
                      { id: 3, text: Dictionary.GetString('milliliters') },
                    ]"
                    item-text="text"
                    item-value="id"
                    :rules="[(v) => !!v || Dictionary.GetString('noempty')]"
                  >
                  </v-select>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-select hide-details="" :label="Dictionary.GetString('numberofmenus')" class="shrink font-weight-bold text-xl-caption" dense single-line v-model="Product.Item.group" filled rounded prepend-inner-icon="mdi-food" :menu-props="{ offsetY: true, closeOnClick: true }" :items="Products.NumberOfMenus.Items"> </v-select>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-text-field filled rounded hide-details="" @click:append="[(Calc.Open = true), (Calc.Switcher = 5), (Calc.Item = Product.Item)]" @click="[(Calc.Open = true), (Calc.Switcher = 5), (Calc.Item = Product.Item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-label-percent-outline" dense single-line :label="Dictionary.GetString('tax')" v-model="Product.Item.tax" :rules="[(v) => !!v || Dictionary.GetString('noempty')]" type="number" class="NoNumberControll font-weight-bold text-xl-caption"> </v-text-field>
                </v-col>
                <v-col cols="3" class="pt-0">
                  <v-select hide-details="" :label="Dictionary.GetString('addcategory')" class="shrink font-weight-bold text-xl-caption" dense single-line v-model="Product.Item.category_id" filled rounded prepend-inner-icon="mdi-shape" :menu-props="{ offsetY: true, closeOnClick: true }" :items="Categories" :item-text="Dictionary.GetTheRightValue('name_ar', 'name_tr', 'name_de')" item-value="id" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-select>
                </v-col>

                <v-col cols="1" class="pr-6 mb-1 my-0 mx-0">
                  <v-btn color="info" small v-bind:fab="$vuetify.breakpoint.sm ? false : true" @click="[(CategoriesDialog = true)]">
                    <v-icon>mdi-pencil-plus-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled rounded hide-details="" @click:append="[(Calc.Open = true), (Calc.Switcher = 2), (Calc.Item = Product.Item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-library-shelves" :label="Dictionary.GetString('price')" v-model="Product.Item.price" dense single-line :rules="[(v) => !!v || Dictionary.GetString('noempty')]" type="number" class="NoNumberControll font-weight-bold text-xl-caption"> </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled rounded hide-details="" @click:append="[(Calc.Open = true), (Calc.Switcher = 3), (Calc.Item = Product.Item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-library-shelves" dense single-line :label="Dictionary.GetString('offer')" v-model="Product.Item.offer_price" type="number" class="NoNumberControll font-weight-bold text-xl-caption"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled rounded hide-details="" @click:append="[(Calc.Open = true), (Calc.Switcher = 6), (Calc.Item = Product.Item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-library-shelves" dense single-line :label="Dictionary.GetString('costprice')" v-model="Product.Item.cost_price" type="number" class="NoNumberControll font-weight-bold text-xl-caption"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled rounded hide-details="" @click:append="[(Calc.Open = true), (Calc.Switcher = 7), (Calc.Item = Product.Item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-library-shelves" dense single-line :label="Dictionary.GetString('pledge')" v-model="Product.Item.pledge" type="number" class="NoNumberControll font-weight-bold text-xl-caption"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-checkbox hide-details="" v-model="Product.Item.unlimited" class="mx-2 font-weight-bold text-xl-caption" dense single-line :label="Dictionary.GetString('quantityunlimited')" :false-value="0" :true-value="1"></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled rounded hide-details="" @click:append="[(Calc.Open = true), (Calc.Switcher = 1), (Calc.Item = Product.Item)]" @click="[(Calc.Open = true), (Calc.Switcher = 1), (Calc.Item = Product.Item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-library-shelves" dense single-line :disabled="Product.Item.unlimited == 1" :label="Dictionary.GetString('quantity')" v-model="Product.Item.QTY" :value="Product.Item.unlimited == true ? (Product.Item.QTY = -1) : (Product.Item.QTY = Product.Item.QTY)" :rules="[(v) => !!v || Dictionary.GetString('noempty')]" type="number" class="NoNumberControll font-weight-bold text-xl-caption"> </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-checkbox hide-details="" v-model="Product.Item.weightable" class="mx-2 font-weight-bold text-xl-caption" dense single-line :label="Dictionary.GetString('weightable')" :false-value="0" :true-value="1"></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled rounded hide-details="" @click:append="[(Calc.Open = true), (Calc.Switcher = 4), (Calc.Item = Product.Item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-barcode-scan" dense single-line :label="Dictionary.GetString('barcode')" v-model="Product.Item.barcode" type="number" class="NoNumberControll font-weight-bold text-xl-caption"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-checkbox hide-details="" v-model="Product.Item.shown" class="mx-2 font-weight-bold text-xl-caption" dense single-line :label="Dictionary.GetString('showonwebsite')" :false-value="0" :true-value="1"> </v-checkbox>
                </v-col>
                <v-col cols="4" v-if="user.PrivateSetting.NumberOfMenus == 1">
                  <v-checkbox hide-details="" v-model="Product.Item.favorite" class="mx-2 font-weight-bold text-xl-caption" dense single-line :label="Dictionary.GetString('fav')" :false-value="0" :true-value="1"></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-if="user.PrivateSetting.NumberOfMenus > 1"
                    single-line
                    :label="Dictionary.GetString('fav')"
                    v-model="Product.Item.favorite"
                    filled
                    rounded
                    hide-details=""
                    dense
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    :items="[
                      { name: Dictionary.GetString('undefined'), value: 0 },
                      { name: Dictionary.GetString('fav') + ' ' + 1, value: 1 },
                      { name: Dictionary.GetString('fav') + ' ' + 2, value: 2 },
                    ]"
                    item-text="name"
                    item-value="value"
                  ></v-select>
                </v-col>

                <v-col cols="4">
                  <v-file-input filled rounded hide-details="" :prepend-icon="null" prepend-inner-icon="mdi-upload" dense single-line clearable :label="Dictionary.GetString('file')" v-model="Product.Item.photoValue" class="Pointer font-weight-bold text-xl-caption"> </v-file-input>
                </v-col>
                <v-col cols="3">
                  <v-img :src="GeneralClasses.LiekasseDomain() + '/' + Product.Item.photo" contain height="120">
                    <template v-slot:placeholder>
                      <v-card height="100%" elevation="0" class="text-center">
                        <v-icon x-large>mdi-image-off-outline</v-icon>
                      </v-card>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-row align="center" justify="center" class="my-1 pl-3">
                <v-btn large class="mx-3 mt-3" color="error" @click="[(Product.Type = ''), (Product.Open = false), (Product.Item = {}), (Product.Title = '')]" rounded>
                  <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-close-circle-outline</v-icon>
                  {{ Dictionary.GetString("cancel") }}
                </v-btn>
                <v-btn large class="mx-9 mt-3" color="success" @click="Submit(Product.Item, true)" rounded>
                  <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-content-save-all-outline </v-icon>
                  {{ Dictionary.GetString("save") }}
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-container>
    </v-dialog>
    <v-data-table v-if="Products.List" :no-results-text="Dictionary.GetString('noresult')" :no-data-text="Dictionary.GetString('noresult')"
     :headers="Products.Header" item-key="id" :loading="Products.Loading" :loading-text="Dictionary.GetString('loading')" hide-default-footer :items-per-page="Products.Settings.RowsPerPage" :items="Products.Items" class="elevation-1 font-weight-bold text-xl-caption">
      <template v-slot:[`item.QTY`]="{ item }">
        <v-row align="center" class="my-3">
          <v-tooltip v-if="item.QTY <= 3 && item.QTY >= 0" :left="!$vuetify.rtl" :right="$vuetify.rtl" :color="LSSettings.UserInfo.Dark ? 'red' : ''">
            <template v-slot:activator="{ on, attrs }">
              <v-icon large color="warning" v-bind:class="$vuetify.rtl ? 'ml-1' : 'mr-1'" v-bind="attrs" v-on="on"> mdi-information-outline</v-icon>
            </template>
            <span> <v-icon dark> mdi-information-outline </v-icon>&nbsp;{{ Dictionary.GetString("quantity") }} : {{ item.QTY }} </span>
          </v-tooltip>
          <v-text-field v-show="item.QTY >= 0" readonly v-model="item.QTY" class="shrink" @click:append="[(Calc.Open = true), (Calc.Switcher = 10), (Calc.Item = item)]" @click="[(Calc.Open = true), (Calc.Switcher = 10), (Calc.Item = item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-library-shelves" :label="Dictionary.GetString('barcode')" rounded dense filled single-line hide-details=""></v-text-field>
          <v-chip v-show="item.QTY < 0">{{ Dictionary.GetString("quantityunlimited") }}</v-chip>
        </v-row>
      </template>
      <template v-slot:[`item.barcode`]="{ item }">
        <v-chip>{{ item.barcode }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn x-small fab elevation="0" outlined class="mx-1 my-1" @click="[(Print.Open = true), (Print.Item = item)]">
          <v-icon> mdi-printer-settings </v-icon>
        </v-btn>
        <v-btn x-small fab elevation="0" color="info" class="mx-1 my-1" dark @click="[(Product.Type = 'Old'), (Product.Open = true), (Product.Item = item), (Product.Title = Dictionary.GetString('edit'))]">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn x-small fab elevation="0" color="secondary" class="mx-1 my-1" dark @click="[(Product.Type = 'New'), (Product.Item = item), Duplicate(item)]">
          <v-icon> mdi-content-copy </v-icon>
        </v-btn>
        <v-btn x-small fab elevation="0" color="error" class="mx-1 my-1" dark @click="Delete(item)">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-data-iterator v-else item-key="id" must-sort :loading="Products.Loading" hide-default-footer :items-per-page="Products.Settings.RowsPerPage" sort-by="QTY" :items="Products.Items">
      <template v-slot:loading>
        <v-row justify="center" align="center" class="mt-1">
          <v-chip justify="center" align="center" color="primary" large>
            <v-progress-circular indeterminate color="white" v-bind:class="$vuetify.rtl ? 'ml-3' : 'mr-3'"> </v-progress-circular>
            {{ Dictionary.GetString("loading") }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-row justify="center" align="center" class="mt-1">
          <v-chip justify="center" align="center" color="error" large>
            {{ Dictionary.GetString("noresult") }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:no-results>
        <v-row justify="center" align="center" class="mt-1">
          <v-chip justify="center" align="center" color="error" large>
            {{ Dictionary.GetString("noresult") }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col cols="12" md="3" v-for="item in props.items" :key="item.id">
            <v-card height="100%" elevation="3" class="pt-2">
              <v-btn x-small fab elevation="0" outlined class="mx-2" @click="[(Print.Open = true), (Print.Item = item)]">
                <v-icon> mdi-printer-settings </v-icon>
              </v-btn>
              <v-img :src="GeneralClasses.LiekasseDomain() + '/' + item.photo" height="12vh" :aspect-ratio="1 / 1" contain class="Pointer">
                <template v-slot:placeholder>
                  <v-card height="100%" elevation="3" class="text-center py-2">
                    <v-icon x-large>mdi-image-off-outline</v-icon>
                  </v-card>
                </template>
              </v-img>
              <v-card-subtitle style="height: 13vh">
                <v-chip small> <v-icon> mdi-barcode-scan </v-icon>&nbsp;{{ item.barcode }} </v-chip>
                <div class="font-weight-bold mt-2">
                  {{ Dictionary.GetTheRightValue(item.name_ar, item.name_tr, item.name_de) }}
                </div>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions>
                <v-text-field v-show="item.QTY >= 0" readonly v-model="item.QTY" @click:append="[(Calc.Open = true), (Calc.Switcher = 10), (Calc.Item = item)]" append-icon="mdi-calculator" prepend-inner-icon="mdi-library-shelves" :label="Dictionary.GetString('barcode')" rounded dense filled single-line hide-details=""> </v-text-field>
                <v-chip color="secondary" v-show="item.QTY < 0">{{ Dictionary.GetString("quantityunlimited") }}</v-chip>
                <v-spacer />
                <v-tooltip v-if="item.QTY <= 3 && item.QTY >= 0" :left="!$vuetify.rtl" :right="$vuetify.rtl" :color="LSSettings.UserInfo.Dark ? 'red' : ''">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon large color="warning" class="mx-1" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span> <v-icon dark> mdi-information-outline </v-icon>&nbsp;{{ Dictionary.GetString("quantity") }} : {{ item.QTY }} </span>
                </v-tooltip>
                <v-btn x-small fab elevation="0" color="secondary" class="mx-1 my-1" dark @click="[(Product.Type = 'New'), (Product.Item = item), Duplicate(item)]">
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
                <v-btn x-small fab elevation="0" color="info" class="mx-1 my-1" dark @click="[(Product.Type = 'Old'), (Product.Open = true), (Product.Item = item), (Product.Title = Dictionary.GetString('edit'))]">
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
                <v-btn x-small fab elevation="0" color="error" class="mx-1 my-1" dark @click="Delete(item)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";

// Components
import Title from "../Shared/Title.vue";
import Calc from "../Shared/Calc.vue";
import Categories from "../Pages/Categories.vue";
import PrintsProduct from "../Shared/Prints/PrintsProduct.vue";

// JS Libraries
import moment from "moment";

export default {
  props: ["user"],
  name: "Products",
  components: { Title, Calc, PrintsProduct, Categories },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      BarcodeOptions: {
        height: 20,
        width: 1,
        displayValue: true,
        default: true,
        textPosition: "bottom",
        textAlign: "center",
      },

      Calc: {
        Open: false,
        Switcher: null,
        Item: null,
      },

      Print: {
        Open: false,
        Item: null,
      },

      Product: {
        Title: "",
        Open: false,
        Item: {},
        Type: "",
      },

      Products: {
        Category: 1,
        NumberOfMenus: {
          Items: [],
        },
        Search: {
          Value: "",
          Sync: "",
          Items: [],
          Loading: false,
        },
        Barcode: "",
        Switcher: 0,
        Loading: true,
        List: true,
        Header: [
          {
            text: "#",
            align: "start",
            value: "id",
          },
          {
            text: Dictionary.GetString("barcode"),
            align: "start",
            value: "barcode",
          },
          {
            text: Dictionary.GetString("quantity"),
            value: "QTY",
            align: "start",
          },
          {
            text: Dictionary.GetString("Productprice"),
            value: "offer_price",
          },
          {
            text: Dictionary.GetString("title"),

            value: Dictionary.GetTheRightValue("name_ar", "name_tr", "name_de"),
          },
          { text: "Actions", align: "end", value: "actions", sortable: false },
        ],
        Items: [],
        Settings: {
          group: 1,
          RowsPerPage: 50,
          CurrentPage: 1,
          Total: 0,
        },
      },

      Categories: [],
      CategoriesDialog: false,
    };
  },
  watch: {
    "Products.Search.Value"(Value) {
      this.Products.Settings.CurrentPage = 1;
      if (Value == "" || Value == null) {
        this.ProductsSwitcher(0);
      } else {
        this.ProductsSwitcher(2);
      }
    },
    "Products.Search.Sync"(Value) {
      if (Value != null) {
        this.Products.Search.Loading = true;
        this.$http
          .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API6()) + Value)
          .then((Result) => {
            this.Products.Search.Items = Result.data.data;
            this.Products.Search.Loading = false;
          })
          .catch((Error) => {
            this.Products.Search.Items = [];
            this.Products.Search.Loading = false;
          });
      }
    },
    "Calc.Open"(Value) {
      if (!Value) {
        this.Calc.Switcher = null;
        this.Calc.Item = null;
      }
    },
    user(Value) {
      this.user = Value;
    },
    CategoriesDialog(Value) {
      if (!Value) {
        this.GetCategoriesAPI();
      }
    },
  },
  methods: {
    ResetFocus: function () {
      this.$refs.Barcode.focus();
      this.Products.Barcode = "";
    },
    UpdateSortProductInLocalStorage(Type) {
      this.LSSettings.SortProduct.Check = Type == "name" ? "name_" + this.LSSettings.UserInfo.Language : "created_at";
      console.log(this.LSSettings.SortProduct.Check);
      localStorage.setItem(this.$GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
    },
    ProductsSwitcher(Switcher) {
      this.Products.Switcher = Switcher;
      if (this.Products.Switcher == 0) {
        this.GetProductsAPINormal();
      } else if (this.Products.Switcher == 1) {
        this.GetProductsAPIBarcode();
      } else if (this.Products.Switcher == 2) {
        this.GetProductsAPISearch();
      } else if (this.Products.Switcher == 3) {
        this.GetProductsAPICategory();
      }
      this.ResetFocus();
    },
    CalcSave(Object) {
      var LSSettings = JSON.parse(localStorage.getItem(this.GeneralClasses.LSSettings()));
      if (LSSettings.Calc != null) {
        switch (Object) {
          case 0:
            this.Products.Barcode = LSSettings.Calc;
            this.ProductsSwitcher(1);
            break;
          case 1:
            this.Calc.Item.QTY = LSSettings.Calc;
            this.Calc.Item.photo = [];
            break;
          case 10:
            this.Calc.Item.QTY = LSSettings.Calc + this.Calc.Item.QTY;
            this.Calc.Item.photo = [];
            console.log(LSSettings.Calc);
            this.Submit(this.Calc.Item, false);
            console.log(this.Calc.Item.QTY);

            break;
          case 2:
            this.Calc.Item.price = LSSettings.Calc;
            this.Calc.Item.photo = [];
            break;
          case 3:
            this.Calc.Item.offer_price = LSSettings.Calc;
            this.Calc.Item.photo = [];
            break;
          case 4:
            this.Calc.Item.barcode = LSSettings.Calc;
            this.Calc.Item.photo = [];
            break;
          case 5:
            this.Calc.Item.tax = LSSettings.Calc;
            this.Calc.Item.photo = [];
            break;
          case 6:
            this.Calc.Item.cost_price = LSSettings.Calc;
            this.Calc.Item.photo = [];
            break;
          case 7:
            this.Calc.Item.pledge = LSSettings.Calc;
            this.Calc.Item.photo = [];
            break;

          default:
          // Do Nothing
        }
      }
      this.Calc.Open = false;
    },
    Delete(Item) {
      this.$swal
        .fire({
          title: this.Dictionary.GetString("delete"),
          showCancelButton: true,
          confirmButtonText: this.Dictionary.GetString("apply"),
          denyButtonText: this.Dictionary.GetString("cancel"),
          icon: "error",
        })
        .then((SwalResult) => {
          if (SwalResult.isConfirmed) {
            this.$http
              .delete(this.GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API2()) + Item.id, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
              .then((ResultLiekasse) => {
                this.ProductsSwitcher(0);
              })
              .catch((Error) => console.log("Error 0 : ", Error));
          }
        });
    },
    Duplicate(Item) {
      Item.barcode = "";
      Item.photo = [];
      this.SubmitOP(Item);
    },
    Submit(Item, Validate) {
      if (Validate) {
        if (this.$refs.InputForm.validate()) {
          this.SubmitOP(Item);
        }
      } else {
        this.SubmitOP(Item);
      }
    },
    SubmitOP(Item) {
      undefined;
      const Data = new FormData();
      var EmptyBarcode = String(moment(new Date()).format("DDMMYYYYHHmmss"));
      Item.photo = Item.photoValue == null ? [] : Item.photoValue;
      Item.barcode = Item.barcode == "" || Item.barcode == null ? EmptyBarcode : Item.barcode;
      Item.offer_price = Item.offer_price == "" || Item.offer_price == null ? Item.price : Item.offer_price;
      Item.name_ar = Item.name_ar == "" || Item.name_ar == null ? Item.name_de : Item.name_ar;
      Item.name_tr = Item.name_tr == "" || Item.name_tr == null ? Item.name_de : Item.name_tr;
      Item.cost_price = Item.cost_price == "" || Item.cost_price == null ? Item.price : Item.cost_price;
      Item.printer_nr = Item.printer_nr == "" || Item.printer_nr == null ? 0 : Item.printer_nr;
      Item.group = Item.group == "" || Item.group == null ? 1 : Item.group;
      Item.company = Item.company == "" || Item.company == null ? "_" : Item.company;
      Item.pledge = Item.pledge == "" || Item.pledge == null ? 0 : Item.pledge;
      Item.unlimited = Item.QTY == -1 ? 1 : 0;

      Item.shown = Item.shown == "" || Item.shown == null ? 0 : Item.shown;
      Item.favorite = Item.favorite == "" || Item.favorite == null ? 0 : Item.favorite;
      Item.weightable = Item.weightable == "" || Item.weightable == null ? 0 : Item.weightable;

      this.$delete(Item, "photoValue");
      Object.keys(Item).forEach((Record) => {
        Data.append(Record, Item[Record]);
      });
      var Source = this.Product.Type == "New" ? this.GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API2()) : this.GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API2()) + Item.id;
      this.$http
        .post(Source, Data, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          this.$toast.open({
            message: this.Dictionary.GetString("done"),
            type: "success",
            position: "bottom",
          });
          this.ProductsSwitcher(0);
          this.Product.Type = "";
          this.Product.Open = false;
          this.Product.Item = {};
          this.Product.Title = "";
        })
        .catch((Error) => {
          console.log(Error);
          this.$toast.open({
            message: this.Dictionary.GetString("barcode"),
            type: "error",
            position: "bottom",
          });
        });
    },
    GetProductsAPICategory() {
      this.Products.Loading = true;
      this.Products.Items = [];
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API4()) + this.Products.Category + "?page=" + this.Products.Settings.CurrentPage, this.GeneralClasses.GetHeaderValueWithGroup(this.InstallValues.LiekasseAT, this.Products.Settings.RowsPerPage, this.Products.Settings.group))
        .then((Result) => {
          if (Result != null) {
            this.Products.Items = Result.data.data;
            this.Products.Settings.Total = Result.data.meta.last_page;
            this.Products.Loading = false;
          }
        })
        .catch((Error) => {
          console.log("Error 0 : ", Error);
          this.Products.Loading = false;
        });
    },

    GetProductsAPISearch() {
      if (this.Products.Search.Value != "") {
        this.Products.Loading = true;
        this.Products.Items = [];
        this.$http
          .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API6()) + this.Products.Search.Value + "?page=" + this.Products.Settings.CurrentPage)
          .then((Result) => {
            if (Result != null) {
              this.Products.Items = Result.data.data;
              this.Products.Settings.Total = Result.data.meta.last_page;
              this.Products.Loading = false;
              this.Products.Search.Sync = "";
            }
          })
          .catch((Error) => {
            console.log("Error 0 : ", Error);
            this.Products.Loading = false;
          });
      } else {
        this.ProductsSwitcher(0);
      }
    },
    GetProductsAPIBarcode() {
      if (this.Products.Barcode != "") {
        this.Products.Loading = true;
        this.Products.Items = [];
        this.$http
          .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API3()) + this.Products.Barcode, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            if (Result != null) {
              this.Products.Items.push(Result.data.data);
              this.Products.Settings.Total = 1;
              this.Products.Loading = false;
            }
          })
          .catch((Error) => {
            console.log("Error 0 : ", Error);
            this.Products.Loading = false;
          });
      } else {
        this.ProductsSwitcher(0);
      }
    },
    GetProductsAPINormal: function () {
      this.Products.Loading = true;
      this.Products.Items = [];
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API2()) + "?page=" + this.Products.Settings.CurrentPage, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Products.Settings.RowsPerPage))
        .then((Result) => {
          this.Products.Items = Result.data.data;
          this.Products.Settings.Total = Result.data.meta.last_page;
          this.Products.Loading = false;
        })
        .catch((Error) => {
          console.log("Error 0 : ", Error);
          this.Products.Loading = false;
        });
    },
    GetCategoriesAPI: function () {
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API1()), this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, 1000000000000000))
        .then((Result) => {
          this.Categories = Result.data.data;
        })
        .catch((Error) => console.log("Error 0 : ", Error));
    },
  },
  mounted() {
    for (var i = 1; i <= this.user.PrivateSetting.NumberOfMenus; i++) {
      this.Products.NumberOfMenus.Items.push(i);
    }
    this.ProductsSwitcher(0);
    this.GetCategoriesAPI();
  },
};
</script>

