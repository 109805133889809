<template>
  <div>
    <v-dialog v-model="Calc.Open" width="60vw" hide-overlay origin=" bottom right">
      <v-container class="background pa-7" fluid>
        <CalcWithEmit v-if="Calc.Open" @calcclose="[(Calc.Open = false)]" @calcvalue="GetCalcResult" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="Print.Open" width="40vw">
      <v-container class="background pa-7" fluid>
        <PrintsOrder v-if="Print.Open" :order="Print.Item" :user="user" :type="0" />
      </v-container>
    </v-dialog>
    <v-data-iterator item-key="id" must-sort hide-default-footer sort-by="id" :sort-desc="true" :items="Items" :items-per-page="Items.length">
      <!-- Repair components -->
      <template v-slot:loading>
        <v-row justify="center" align="center" class="mt-1 mx-1">
          <v-chip justify="center" align="center" color="primary" large class="mt-3">
            <v-progress-circular indeterminate color="white" v-bind:class="$vuetify.rtl ? 'ml-3' : 'mr-3'"></v-progress-circular>
            {{ Dictionary.GetString("loading") }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-row justify="center" align="center" class="mt-1 mx-1">
          <v-chip justify="center" align="center" color="error" large class="mt-3">
            {{ Dictionary.GetString("noresult") }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:no-results>
        <v-row justify="center" align="center" class="mt-1 mx-1">
          <v-chip justify="center" align="center" color="error" large class="mt-3">
            {{ Dictionary.GetString("noresult") }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:default="props">
        <div>
          <v-row align="stretch" justify="center">
            <v-col cols="6" v-for="item in props.items" :key="item.id">
              <v-form :ref="'item' + item.id" lazy-validation>
                <v-card height="100%" elevation="0" outlined class="rounded-xl mb-n2 pa-1">
                  <v-checkbox v-model="item.Checked" class="mr-2" :label="Dictionary.GetTheRightValue(item.product_name_ar, item.product_name_tr, item.product_name_de)"></v-checkbox>
                  <v-divider class="mb-3" />
                  <v-card-actions v-if="item.pledge != 0" class="ma-0 px-4 side3 rounded-xl mb-2 mx-2 p">
                    <span class="font-weight-bold">{{ Dictionary.GetString("pledge") }}</span
                    ><v-spacer />{{ Number(item.pledge).toFixed(2) }} €
                  </v-card-actions>
                  <v-card-actions class="ma-0 px-4 side3 rounded-xl mb-2 mx-2 p">
                    <span class="font-weight-bold">{{ Dictionary.GetString("Quantitysold") }}</span
                    ><v-spacer /> <span class="text-h6 pl-2">{{ item.QTY }}</span>
                    {{ Dictionary.GetTheRightWeight(item.unit) }}
                  </v-card-actions>
                  <v-card-actions class="ma-0 pt-0">
                    <v-text-field v-if="!Calc.Open" v-model="item.QTYRefund" class="Pointer secondary text--white" dark rounded dense prepend-inner-icon="mdi-library-shelves" filled single-line hide-details="" @click="[(Calc.Open = true), (Calc.ItemID = item.id)]" :placeholder="$Dictionary.GetTheRightWeight(item.unit)"> </v-text-field>
                    <v-chip class="mx-3 py-5">{{ Number(item.offer_price).toFixed(2) }} €</v-chip>
                    <v-spacer class="mx-1" />
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-iterator>
    <v-row justify="center" align="center" class="mt-2 px-3">
      <v-col cols="12">
        <v-btn block class="info rounded-pill" @click="MakeCalculations()">{{ Dictionary.GetString("apply") }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";
import CalcWithEmit from "../Shared/CalcWithEmit.vue";

// JS Libraries
import { v4 as uuidv4 } from "uuid";

// Components
import PrintsOrder from "../Shared/Prints/PrintsOrder.vue";

export default {
  name: "OrderRefund",
  props: ["user", "order"],
  components: { PrintsOrder, CalcWithEmit },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),
      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      Calc: {
        Open: false,
        ItemID: 0,
        Result: "",
      },

      Order: {
        Items: [],
      },

      Print: {
        Open: false,
        Item: 0,
      },

      Cart: [],
      Items: [],

      ExtraCalculations: {
        order_pledge: 0,
      },
      LiekasseData: {
        paymentMethod: "",
        gift: 0,
        gift_type: true,
        givedMoney: 0,
        returnedMoney: 0,
        brutto: 0,
        table_nr: 0,
      },
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
    order(Value) {
      this.order = Value;
    },
    Items(Value) {
      this.Items = Value;
    },
    "Print.Open"(Value) {
      if (!Value) {
        window.location.reload();
      }
    },
  },
  methods: {
    PrintItem(item) {
      console.log(item);
    },
    GetCalcResult(Value) {
      this.Items.map((Element) => {
        if (Element.id == this.Calc.ItemID) {
          if (Number(Value) > Element.QTY) {
            Element.QTYRefund = Element.QTY;
          } else {
            Element.QTYRefund = Number(Value);
          }
        }
      });
    },
    MakeCalculations() {
      this.Cart.Items = [];
      this.Items.forEach((Element) => {
        // console.log(this.Items);
        if (Element.Checked) {
          this.Cart.Items.push(Element);
        }
      });
      if (this.Cart.Items.length == 0) {
        this.$toast.open({
          message: this.Dictionary.GetString("empty"),
          type: "error",
          position: "bottom",
        });
      } else {
        this.LiekasseData.brutto = Number(this.Cart.Items.map((Item) => Item.offer_price * Item.QTYRefund).reduce((a, b) => a + b, 0)).toFixed(2);

        this.Cart.Items.forEach((Element) => {
          (this.ExtraCalculations.total_pledges = this.ExtraCalculations.total_pledges || []).push(Element.pledge * Element.QTYRefund);
          (this.LiekasseData.product_id = this.LiekasseData.product_id || []).push(Element.product_id);
          (this.LiekasseData.price = this.LiekasseData.price || []).push(Element.offer_price);
          (this.LiekasseData.QTY = this.LiekasseData.QTY || []).push(Element.QTYRefund);
        });

        this.ExtraCalculations.order_pledge = this.ExtraCalculations.total_pledges
          .map(Number)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);

        this.LiekasseData.receipt_nr = this.Order.Items[0].receipt_nr;
        this.LiekasseData.transactionID2 = uuidv4();

        this.ExtraCalculations.MustPay = Number(this.LiekasseData.brutto) + Number(this.ExtraCalculations.order_pledge);
        this.MakeOrder();
      }
    },
    MakeOrder() {
      this.LiekasseData.type = 2;
      this.GeneralClasses.TSESubmit(this.ExtraCalculations.MustPay, this.LiekasseData, this.user.PrivateSetting, this.InstallValues, this.user.Liekasse[0].clientID2)
        .then((Result) => {
          console.log(Result);
          this.Print.Item = Result.OrderId;
          this.Print.Open = Result.PrintReady;
        })
        .catch((Error) => {
          console.log(Error);
        });
    },
  },
  mounted() {
    this.LiekasseData.paymentMethod = JSON.parse(JSON.stringify(this.order.payment_method));
    this.Order.Items.push(this.order);
    // console.log(this.Order.Items);
    this.Items = this.Order.Items[0].order_detals;
    this.Items.forEach((Element) => {
      Element.QTYRefund = Element.QTY;
    });
  },
};
</script>
