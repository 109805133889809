<template>
  <div>
    <v-app-bar v-bind:fixed="InstallValues.FixedHeader" color="px-1 secondary elevation-3" dark height="55">
      <v-app-bar-nav-icon @click.stop="Drawer = !Drawer"></v-app-bar-nav-icon>
      <v-chip large class="mx-4 font-weight-bold px-10" style="height: 40px" v-if="!$vuetify.breakpoint.smAndDown"> <v-icon size="30" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-clock-outline</v-icon>&nbsp;&nbsp;{{ Time }} </v-chip>
      <v-btn depressed v-show="false" rounded color="success" @click="SignOut()">
        {{ Dictionary.GetString("Whenanerrorclickhere") }}
      </v-btn>
      <v-dialog v-model="Inovice.Open" width="60vw">
        <v-container class="background pt-0" fluid>
          <v-row justify="end" class="pa-0 ma-0 pb-0 mb-0">
            <v-icon large @click="Inovice.Open = !Inovice.Open" class="pt-2">mdi-close-circle-outline</v-icon>
          </v-row>
          <InoviceDetails :user="user"></InoviceDetails>
        </v-container>
      </v-dialog>
      <v-chip @click="Inovice.Open = true" v-if="user.PrivateSetting.Locked" color="warning" class="pa-5">
        <v-icon large v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-information-outline</v-icon>
        {{ user.PrivateSetting.Locked ? Dictionary.GetString("warningClose") : "" }}
      </v-chip>
      <v-spacer />
      <v-btn v-if="!$vuetify.breakpoint.smAndDown" class="mx-2 rounded-pill" color="success" :to="'/' + LSSettings.UserInfo.Language + '/main/panel/pages/userscreen'" target="_blank">
        <v-icon color="black">mdi-monitor-screenshot</v-icon>
      </v-btn>
      <v-chip class="px-6 py-5 justify-center rounded-pill" style="min-width: 50px" :color="onLine == true ? 'success' : 'error'">
        <v-icon color="black" small v-if="onLine == true">mdi-wifi</v-icon>
        <v-icon small v-else>mdi-wifi-off</v-icon>
      </v-chip>
    </v-app-bar>
    <v-navigation-drawer v-model="Drawer" fixed temporary :right="$vuetify.rtl">
      <v-list nav dense>
        <v-list-item>
          <v-list-item-content class="mt-0 pt-0 mb-0">
            <v-list-item-title class="subtitle-1 mb-0">{{ user.Liekasse[0] == undefined ? "" : user.Liekasse[0].name }} </v-list-item-title>
            <v-list-item-subtitle>{{ user.Liekasse[0] == undefined ? "" : user.Liekasse[0].email }} </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar class="mt-0 pt-0 mb-0">
            <v-btn icon fab @click="Drawer = !Drawer">
              <v-icon>mdi-close-box-multiple-outline</v-icon>
            </v-btn>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
      <v-divider class="black" />

      <v-list nav dense>
        <v-list-item-group v-model="ListGroup">
          <v-list-item
            v-for="MenuItem in [
              { Role: GeneralClasses.Check('J1'), Title: Dictionary.GetString('usermanagement'), Icon: 'mdi-account-group-outline', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/usermanagement' },
              { Role: GeneralClasses.Check('I1'), Title: Dictionary.GetString('taktmanagement'), Icon: 'mdi-tag-plus', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/tag' },
              { Role: GeneralClasses.Check('B1'), Title: Dictionary.GetString('addproducts'), Icon: 'mdi-library-shelves', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/products' },
              { Role: GeneralClasses.Check('E1'), Title: Dictionary.GetString('addcategories'), Icon: 'mdi-shape', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/categories' },
              { Role: true, Title: Dictionary.GetString('addorder'), Icon: 'mdi-cart-plus', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/cashregister' },
              { Role: GeneralClasses.Check('F1'), Title: Dictionary.GetString('addorders'), Icon: 'mdi-cart-outline', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/orders' },
              { Role: GeneralClasses.Check('D1'), Title: user.PublicSetting.Type == 2 || user.PublicSetting.Type == 3 ? Dictionary.GetString('managecart2') : Dictionary.GetString('managecart1'), Icon: 'mdi-content-save', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/carts' },
              { Role: GeneralClasses.Check('G1'), Title: Dictionary.GetString('managebox'), Icon: 'mdi-finance', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/box' },
              { Role: GeneralClasses.Check('G1'), Title: Dictionary.GetString('manageprinters'), Icon: 'mdi-printer', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/printers' },
              { Role: GeneralClasses.Check('H1'), Title: Dictionary.GetString('generalstatistics'), Icon: 'mdi-poll', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/statistics' },
              { Role: GeneralClasses.Check('K1'), Title: Dictionary.GetString('payment_process'), Icon: 'mdi-credit-card-outline', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/payment' },
              { Role: GeneralClasses.Check('M1'), Title: Dictionary.GetString('Cashiermanagement'), Icon: 'mdi-web-sync', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/systemmanagement' },
              { Role: false, Title: Dictionary.GetString('PointSaleanagement'), Icon: 'mdi-cart-variant', Link: '/' + LSSettings.UserInfo.Language + '/main/panel/pages/liefood/pointsalemanagement' },
            ]"
            :key="MenuItem.Title"
            :to="MenuItem.Link"
            link
            @click="Drawer = false"
            v-show="MenuItem.Role"
          >
            <v-list-item-icon>
              <v-icon>{{ MenuItem.Icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ MenuItem.Title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider class="black my-1" />
      <v-col class="pa-1 text-center">
        <v-switch v-bind:class="$vuetify.rtl ? 'pr-3' : 'pl-3'" v-model="InstallValues.Theme" hide-details="" inset flat :label="Dictionary.GetString('choosetheme')" @change="ChangeTheme" dense></v-switch>
        <v-switch class="mx-3 mt-2" v-model="InstallValues.Tablet" inset flat :label="Dictionary.GetString('choosetablet')" @change="ChangeTablet" dense hide-details=""></v-switch>
        <v-select
          class="mx-1 mt-2 shrink"
          background-color="info"
          v-model="InstallValues.CurrentLanguage"
          filled
          hide-details=""
          rounded
          dense
          dark
          prepend-inner-icon="mdi-web"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          :items="[
            { name: 'Deutsch', value: 'de' },
            { name: 'اللغة العربية', value: 'ar' },
            { name: 'Türkçe', value: 'tr' },
          ]"
          item-text="name"
          item-value="value"
          @change="ChangeLanguage"
        ></v-select>
        <v-divider class="my-2 black" />
        <div class="mb-2">
          <v-btn block rounded dark color="secondary" @click="SignOut">
            <v-icon left>mdi-logout</v-icon>
            {{ Dictionary.GetString("logout") }}
          </v-btn>
          <v-btn v-if="user.Liekasse[0] == undefined ? false : user.Liekasse[0].email == 'superadmin@liekasse.de'" block :to="'/' + LSSettings.UserInfo.Language + '/main/panel/pages/superadmin/users'" rounded dark color="warning" class="mt-2"> Super Admin </v-btn>
        </div>
        <div class="text--disabled caption font-weight-bold font-weight-black">Version {{ InstallValues.Version }}</div>
        <div class="caption font-weight-bold font-weight-black pl-4">
          <v-chip color="transparent"> <v-img src="/fav.png" width="20" contain position="left" />&nbsp;&nbsp;Liekasse © {{ new Date().getFullYear() }} </v-chip>
        </div>
      </v-col>
    </v-navigation-drawer>
  </div>
</template>
 
<script>
// JS Scripts
import Dictionary from "../../SubClasses/Dictionary.js";
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import InoviceDetails from "./InoviceDetails.vue";
// JS Libraries
import moment from "moment";

export default {
  props: ["user"],
  name: "Header",
  components: { InoviceDetails },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      moment: moment,
      Drawer: false,
      ListGroup: null,
      onLine: navigator.onLine,
      Time: null,
      Inovice: {
        Open: false,
      },
      InstallValues: {
        CurrentLanguage: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Language,
        Theme: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Dark,
        Tablet: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).Tablet,
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
        Version: document.querySelector('meta[name="description"]').content,
      },
    };
  },

  watch: {
    $route() {
      this.HeaderSetting();
    },
    user(Value) {
      this.user = Value;
    },
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
    ListGroup() {
      this.Drawer = false;
    },
  },
  methods: {
    HeaderSetting: function () {
      var Pages = ["/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/cashregister", "/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/cashregister/"];
      if (Pages.includes(this.$route.fullPath)) {
        this.InstallValues.FixedHeader = true;
      } else {
        this.InstallValues.FixedHeader = false;
      }
    },
    SignOut() {
      this.$http
        .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API111()), null, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          try {
            localStorage.clear();
          } finally {
            window.location.replace("/kasse");
          }
        })
        .catch((Error) => {
          console.log(Error);
        });
    },

    UpdateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },

    ChangeLanguage: function () {
      try {
        this.LSSettings.UserInfo.Language = this.InstallValues.CurrentLanguage;
        localStorage.setItem(this.GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
      } finally {
        this.UpdateSettingDB();
      }
    },
    ChangeTheme: function () {
      try {
        this.LSSettings.UserInfo.Dark = this.InstallValues.Theme;
        localStorage.setItem(this.GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
      } finally {
        this.UpdateSettingDB();
      }
    },
    ChangeTablet: function () {
      try {
        this.LSSettings.Tablet = this.InstallValues.Tablet;
        localStorage.setItem(this.GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
      } finally {
        window.location.replace("/" + this.LSSettings.UserInfo.Language + this.$route.fullPath.substring(3));
      }
    },
    UpdateSettingDB: function () {
      var Data = {
        setting: this.LSSettings.UserInfo,
      };
      if (this.user.Liekasse.length != 0) {
        this.$http
          .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API12UpdateSetting()), Data, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            window.location.replace("/" + this.LSSettings.UserInfo.Language + this.$route.fullPath.substring(3));
          })
          .catch((Error) => {
            console.log(Error);
          });
      } else {
        window.location.replace("/" + this.LSSettings.UserInfo.Language + this.$route.fullPath.substring(3));
      }
    },
  },


  async mounted() {
    this.HeaderSetting();
    window.addEventListener("online", this.UpdateOnlineStatus);
    window.addEventListener("offline", this.UpdateOnlineStatus);
    await setInterval(() => {
      this.Time = this.moment().format("HH:mm:ss - dddd DD.MM.YYYY");
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.UpdateOnlineStatus);
    window.removeEventListener("offline", this.UpdateOnlineStatus);
  },
};
</script>
