import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/Main/Login.vue'
import Install from '../components/Install.vue'
import Home from '../components/Main/Website/Home.vue'
import Userscreen from '../components/Shared/Userscreen'

import Products from '../components/Pages/Products.vue'
import Categories from '../components/Pages/Categories.vue'
import Orders from '../components/Pages/Orders.vue'
import Carts from '../components/Pages/Carts.vue'
import Box from '../components/Pages/Box.vue'
import Printers from '../components/Pages/Printers.vue'
import Statistics from '../components/Pages/Statistics.vue'
import Tag from '../components/Pages/Tag.vue'
import UserManagement from '../components/Pages/UserManagement.vue'
import Payment from '@/components/Pages/Payment.vue'
import POSSystem from '@/views/POSSystem.vue'
import Users from '../components/SuperAdmin/Users.vue'
import Systemmanagement from '../components/Pages/Systemmanagement.vue'

import Axios from 'axios'

Vue.use(VueRouter)
import GeneralClasses from '../SubClasses/GeneralClasses';

var Language = (localStorage.getItem(GeneralClasses.LSSettings()) == null) ? 'de' : JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Language;
const routes = [
  {
    path: '*',
    redirect: '/' + Language
  },
  { path: '/', redirect: '/' + Language  },
  { path: '/kasse', redirect: '/' + Language  + '/main/panel/pages/login', component: Login },

  { path: '/' + Language + '/main/panel/pages/login', component: Login },
  { path: '/' + Language + '/main/panel/pages/install', component: Install },
  { path: '/' + Language + '/main/panel/pages/userscreen', component: Userscreen },
  { path: '/' + Language, component: Home },

  { path: '/' + Language + '/main/panel/pages/products', component: Products, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/categories', component: Categories, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/cashregister', component: POSSystem, meta: { requiresAuth: true } },

  { path: '/' + Language + '/main/panel/pages/orders', component: Orders, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/tag', component: Tag, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/carts', component: Carts, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/box', component: Box, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/printers', component: Printers, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/statistics', component: Statistics, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/usermanagement', component: UserManagement, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/payment', component: Payment, meta: { requiresAuth: true } },
  { path: '/' + Language + '/main/panel/pages/systemmanagement', component: Systemmanagement, meta: { requiresAuth: true } },
   { path: '/' + Language + '/main/panel/pages/superadmin/users', component: Users, meta: { requiresAuthAsSuperAdmin: true } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Axios.get(GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API12()), GeneralClasses.GetHeaderValue(window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT)))
      .then((ResultLiekasse) => {
        next();
      }).catch((Error) => {
        window.location.replace('/' + Language + '/main/panel/pages/login');


      });
  }
  else if (to.matched.some(record => record.meta.requiresAuthAsSuperAdmin)) {
    Axios.get(GeneralClasses.LiekasseDomain() + window.atob(GeneralClasses.API12()), GeneralClasses.GetHeaderValue(window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT)))
      .then((ResultLiekasse) => {
        if (ResultLiekasse.data.email == "superadmin@liekasse.de") {
          next();
        }
        else {

          window.location.replace('/' + Language + '/main/panel/pages/login');

        }
      }).catch((Error) => {
        window.location.replace('/' + Language + '/main/panel/pages/login');

      });
  }
  else {
    next()
  }
})

export default router
